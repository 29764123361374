@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Lato", Sans-Serif;
  scroll-behavior: smooth;
  color: black;
}

.car_hero_image_div {
  height: 540px;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.happy-customers-title {
  font-size: 2rem;
  /* Customize as needed */
  font-weight: bold;
  /* Adjust weight */
  color: #333;
}

.row.d-flex.align-items-center.pb-3.dis-mobile {
  margin-bottom: -50px;
}

.img-res {
  overflow: hidden;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.navbar {
  background-color: white;
}

.nav-item {
  margin-right: 40px;
}

.navbar-brand {
  margin-right: 0;
  width: 27%;

}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }

  /* .navbar-expand-md .navbar-nav .nav-link {
    margin-left: -51px;
    margin-right: 89px;
  } */
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 70%;
  }
}


.nav-btns {
  margin-right: 25px;
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-btns-1 {
  border: 2px solid #ed1c24;
  border-radius: 50px;
  padding: 13px 27px 13px 27px;
  background: none;
  font-size: 18px;
  color: #ed1c24;
  font-weight: 700;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.nav-btns-1:focus,
.nav-btns-2:focus {
  outline: none;
  background: #ed1c24 !important;
  border: none !important;
}

.nav-btns-2 {
  border-radius: 50px;
  padding: 13px 27px 13px 27px;
  background: none;
  font-size: 18px;
  color: #fff !important;
  border: 2px solid white;
  font-weight: 700;
  background: #ed1c24;
}

.nav-btns-3 {
  border: 2px solid #ed1c24;
  border-radius: 0;
  padding: 13px 27px 13px 27px;
  background: none;
  font-size: 18px;
  color: #ed1c24;
  font-weight: 700;
}

.service-box p {
  margin-bottom: 23px;
  /* Adds the desired margin between the paragraph and the button */
}


.nav-btns-2:hover {
  background: #00000000;
  color: #000000 !important;
  border: 2px solid rgb(255, 0, 0);
  transform: scaleY(1.1) !important;
}

.nav-btns-1:hover {
  background: rgb(255, 0, 0);
  color: #ffff !important;
  border: 2px solid #000000;
  transform: scaleY(1.1) !important;
}


.text-icons {
  color: white;
  font-weight: 600;
}

.nav-link {
  color: black;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.nav-wrapper h1 {
  font-size: 24px !important;
  margin-bottom: 0.5rem !important;
}

.sec-text-2 p {
  margin-bottom: 1.5rem !important;
}

.header-text {
  margin-top: 5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 55px;
  color: #ffffff;
}

.card {
  border: none;
  /* Remove border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add a subtle shadow */
  transition: transform 0.2s;
  /* Add a hover effect */
}

.card {
  border: 1px solid #ddd;
  /* Optional: Add a border */
  border-radius: 0;
  /* Remove border radius for rectangular shape */
  height: 100%;
  /* Ensure card takes full height */
  display: flex;
  flex-direction: column;
  /* Stack card content vertically */
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  /* Adjust width as needed */
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  /* Change arrow color */
  border-radius: 50%;
  /* Optional: make arrows circular */
}

.carousel-control-prev {
  left: 10px;
  /* Adjust position */
}

a.btn.btn-danger {
  border-radius: 55px;
  margin-top: 20px;
  color: #ffffff !important;
}

.carousel-control-next {
  right: 10px;
  /* Adjust position */
}

.carousel-inner {
  border: 1px solid #ebcdcd;
  box-shadow: none;
}

.card:hover {
  transform: translateY(-5px);
  /* Lift effect on hover */
}

.border-t {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}

section#Consultancy {
  background-color: rgb(255 255 255);
}

.border-text {
  text-align: center !important;
  padding-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  /* font-size: 55px; */
  color: #ffffff;
  /* border: 2px solid black; */
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.card h3 {
  font-size: 2.5rem !important;
  /* Increase font size for numbers */
  margin: 0;
  /* Remove default margin */
}

.achievement-number {
  font-size: 3rem !important;
  /* Large font size */
  font-weight: bold;
  /* Bold text */
}

.service-box {
  background-color: #ffffff;
  /* Change to desired color */
  border-radius: 15px;
  /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  /* Soft shadow */
  padding: 20px;
  /* Padding inside the box */
  text-align: center;
  /* Center text */
  transition: transform 0.2s;
  /* Transition for hover effect */
}

.service-box img {
  max-width: 100%;
  /* Ensure images are responsive */
  height: auto;
  /* Maintain aspect ratio */
}

.service-box h3 {
  margin: 15px 0;
  /* Spacing above and below heading */
  font-size: 1.5rem !important;
  color: #333;
  /* Text color */
  font-weight: 900;

}

.p3 {
  color: red;
}

.service-box p {
  font-size: 1rem;
  /* Adjust font size */
  color: #666;
  /* Text color */
}

.service-box:hover {
  transform: translateY(-5px);
  /* Lift effect on hover */
}

.card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 45px;
}

.image {
  font-family: "Poppins", sans-serif;
  width: 42%;
}

p.mb-0 {
  color: white;
}

span.ml-1 {
  color: white;
}

li.ms-3 {
  margin: 0 8px;
}

.py-3 {
  padding-top: 5px !important;
  padding-bottom: 0 !important;
}

.col.d-flex.justify-content-center {
  margin-bottom: 5px;
}

.mb-3,
.my-3 {
  margin-bottom: 0rem !important;
}

.card-text,
.para {
  font-size: 17px;
  color: #ffcbcb;
  font-weight: 500;
}

.card-text2 {
  font-size: 17px;
  color: #000;
  font-weight: 500;
}

.hero-text {
  padding-left: 75px;
}

#service {
  width: 100%;
  padding: 40px 0;
  padding: 100px;
  background-color: #ffffff;
}

.card {
  border-radius: 0;
  border: 0;
  opacity: 1;

}

#backg {
  background: url(/public/assets/images/bg.png) no-repeat center bottom;
}

.image-wrapper {
  color: black;
}

.iconDetails {
  width: 100%;
  height: auto;
}

img.img-res.img-res-car {
  padding-top: 80px;
  padding-bottom: 80px;
}

.sec-image-3 {
  width: 46%;
}

a.btn.btn-primary.ms-2.nav-btns-2.nav-btns-3 {
  color: #000000 !important;
}

.container2 {
  /* width: 100%;
            height: auto; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-left: 40px;
  /* margin-bottom: 5rem; */
}

.wrapper-call {
  padding-top: 30px;
}

#footer {
  margin-top: 50px;
  padding: 24px;
  margin-left: 25px;
}

.text-bottom {
  font-size: 14px;
}

.share-icon {
  margin-top: 50px;
}

.text-bottom-1 {
  font-size: 14px;

}

.card {
  width: 100%;
  padding: 30px;
  border-radius: 15px;
}

#service .card {
  width: 30rem !important;
  padding: 30px;
}

.footer {
  background: #d91414;
}

.text-muted,
.text-bottom,
.text-bottom-1,
li.nav-item.mb-2 {
  color: #ffffff !important;
  font-family: montserrat light, helvetica neue, Helvetica, Arial, sans-serif;
  font-size: inherit;
}

.fa {
  color: #ffffff !important;
}

.pxs {
  font-size: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 900px) {
  .head-sec {
    height: auto !important;
  }

  .img-res-car {
    height: 400px !important;
  }

  .text-wrapper h1 {
    padding-left: 10px !important;
  }


  .hero-text {
    padding-left: 40px;
    height: auto !important;
    padding-bottom: 60px;
  }

  .header-text {
    margin-top: 2rem;
    font-size: 42px;
  }

  .hero-text .nav-btns-2 {
    padding: 13px 90px 13px 90px;
  }

  .image {
    width: 100%;
  }

  /* Service area  */
  #service {
    padding: 40px 10px !important;
    height: auto !important;
  }

  #service .card {
    width: 100% !important;
    padding: 20px 10px !important;
    margin: 20px 0px;
  }

  .container2 {
    margin-left: 0px;
    margin-top: 2rem;
  }

  #Consultancy {
    height: auto !important;
  }

  .sec-image-3 {
    width: 100%;
  }

  .sec-text-2 {
    margin: 70px 40px !important;
  }

  .img-fluid {
    width: 100%;
  }


  .pxs {
    font-size: 20px;
  }

  .pxs-2 {
    padding: 0px !important;
    font-size: 12px !important;
    display: block;
  }

  .wrapper-call {
    padding-top: 30px;
  }

  .nav-btns-3 {
    border: 2px solid #ed1c24;
    border-radius: 0;
    background: none;
    padding: 2px 3px;
    font-size: 12px;
    color: #ed1c24;
    font-weight: 700;
    margin: 20px 1px;
  }

  .nav-item {
    margin: 0px;
  }

  .row.align-items-center.m-0 {
    display: none;
  }

  .nav-btns {
    margin-right: 0px;
    align-items: center;
    justify-content: center;
  }

  #footer {
    margin-left: 0px;
    padding: 0px;
  }

  .image-wrapper {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-wrapper img {
    width: 180px;
    height: 60px;
  }

  .nav-wrapper h1 {
    font-size: 16px !important;
    margin-bottom: 0.5rem !important;
    text-align: center;
  }

  .text-wrapper h1 {
    text-align: center;
    padding-top: 10px;
  }

}

@media screen and (min-height: 1080px) {
  .header-text {
    font-size: 80px;
  }

  .card-text,
  .para {
    font-size: 30px;
  }

  .hero-text .nav-btns-2 {
    font-size: 30px !important;
    margin-top: 20px;
  }

  #service {
    display: flex;
    align-items: center;
    justify-content: center
  }

  #service .card {
    width: 70% !important;
    padding: 30px;
    height: 710px;
    box-shadow: 0 16px 65px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    padding-bottom: 20px !important;
  }

  #service .card-title {
    font-size: 55px !important;
  }

  #Consultancy .sec-text-2 {
    margin-top: 17% !important;
    margin-left: 4% !important;
  }

  #Consultancy .sec-text-2 h1 {
    font-size: 55px !important;
  }

  #Consultancy .sec-text-2 p {
    font-size: 30px !important;
  }

  #service .nav-btns-1,
  #service .nav-btns-2 {
    font-size: 30px !important;
    margin-top: 20px;

  }

  .car_hero_image_div {
    height: 770px !important;
  }

  .nav-link {
    font-size: 24px !important;
  }


}