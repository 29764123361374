@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

* {
  font-family: 'Poppins', sans-serif;
}

.MuiLinearProgress-barColorPrimary {
  background-image: linear-gradient(to right, rgb(193 150 150 / 0%), rgba(238, 169, 112, 0.51), #ed1c24) !important;
}

.wrapper-image-box {
  width: 999px;
  min-height: 150px;
  height: auto !important;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #00000080;
  opacity: 1;
  margin-left: 0px;
  font-weight: 600;
  font-size: 16px;

}

.logo-1,
.logo-2 {
  margin-top: 30px;
  height: 75px;
}

.upload-btn {
  margin-top: 20px;
  position: absolute;
  right: 3rem;
}

.logo-text {
  text-align: left;
  font: normal normal medium 12px/16px Poppins;
  font-size: 12px;
  color: #000000;
  opacity: 1;
}


.img {
  padding-bottom: 50px;
  padding-left: 60px;
  padding-right: 20px;
}

.btn-wrap {
  padding: 9px 12px;
  border: 1px solid;
  color: black;
  text-transform: capitalize;
  cursor: pointer !important;
  margin: 5px 1px;
  border: none !important;
}

.sell-a-car .wrapper-days {
  padding-left: 1rem;
  padding-top: 2.5rem;
  width: 600px;
}

.wrapper-days .btn {
  border-radius: 0px !important;
}

.wrapper-days .btn:focus {
  background: #ed1c24 !important;
  color: white;
  outline: none !important;
  /* border: none !important; */
  box-shadow: none !important;
}

.btn-bg {
  background-color: hsl(355deg 67% 96%) !important;
  color: red;
}

.btn-default {
  width: 205px;
  height: 50px;
  background: #ed1c24 0% 0% no-repeat padding-box;
  padding: 10px 30px;
  color: white;
  margin-left: 40px;
  margin-top: 4px;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.btn-default:hover {
  background: #ed1c24 0% 0% no-repeat padding-box;
  padding: 10px 30px;
  color: white;
  margin-top: 4px;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  transform: scale(1.1);
}

.btn-default img {
  margin-right: 10px;
}

.sell_from .sell-para-22 {
  position: absolute;
  top: 18px;
  left: 30px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.658);
  z-index: 1000;
}

.AddBtn {
  margin-top: 2rem;
  display: flex;
}

.AddBtn-btn {
  border: 0;
  color: white !important;
  padding: 15px;
  font-size: 16px;
  background-color: hsl(355deg 67% 96%);
  text-transform: capitalize;
  display: flex;
}

.AddBtn-btn:hover {
  text-decoration: none;
  transform: scale(1.03) !important;
}

.sell-a-car .text-para-33 {
  position: absolute;
  top: 20px;
  left: 35px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.658);
  z-index: 1000;
}

.sell-a-car .fieldset-2 .wrapper {
  font-size: 14px !important;
}

.modal-button {
  padding: 10px 370px 10px 0px;
}

.MuiInput-root {
  padding: 0px;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  font-style: normal !important;
  color: #aab5c2 !important;
}

.MuiSelect-select.MuiSelect-select {
  padding: 10px 100px 10px 20px;
  width: 200px !important;
  color: black;
  opacity: 60%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.MuiSelect-select.MuiSelect-select em {
  font-style: normal !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #fef3f3 !important;
  color: black !important;
  font-weight: 500 !important;
}

.MuiListItem-root.Mui-selected::after {
  content: '✔' !important;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* background-color: #ed1c24; */
  padding: 7px 15px;
  color: #ed1c24;
  transform: rotateZ(10deg);

}

.MuiMenuItem-root:hover {
  background-color: #ed1c24 !important;
  color: white !important;
}

.MuiInput-underline:before {
  content: "" !important;
  border-bottom: none !important;
}

.MuiInput-underline.Mui-focused:after {
  content: "" !important;
  border-bottom: none !important;
}

.sell-a-car #fieldset-2 .MuiSelect-select.MuiSelect-select {
  width: 990px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  height: 30px;
  display: flex;

  align-items: center;
}

.sell-a-car #fieldset-2 span .MuiSelect-select.MuiSelect-select {
  font-weight: normal !important;
}

.MuiMenuItem-root {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.div_priorty .MuiSelect-select.MuiSelect-select {
  width: 200px !important;
}

.sell-a-car #fieldset-2 .set_availability .MuiSelect-select.MuiSelect-select {
  width: 120px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  background: #f5f6f7;
  padding-left: 80px;
  margin: 5px;
}

.time_div .MuiInputBase-input {
  margin: 9px 0px 9px 90px !important;
}

.time_div .MuiInputBase-input:focus {
  outline: none !important;
  border: none !important;
}

.time_div .MuiInput-root {
  background: #f5f6f7;
  margin-top: 5px;
  padding: 0px 10px 0px 0px;
  width: 230px;
  margin-right: 8px;
}

.time_div .MuiInput-root:focus {
  border: none !important;
  outline: none !important;
}

.MuiInput-underline:before {
  border: none !important;
}

.MuiInput-underline:after {
  border: none !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

/* .time_field:after{
      content: "Select time";
      position: absolute;
      top: 19px;
      right: 60px;
      bottom: 0;
      width: 100px;
      background-color: #f5f6f7;
      padding: 0px 0px;
      height: 30px;
      color: black;
      } */
.active .time_field:after {
  z-index: -1 !important;
}

.sellSubmit .col-lg-11 {
  padding-left: 0px !important;
}


.sellSubmit .wrap-text-111 {
  padding: 20px 80px !important;
}

.sellSubmit .sell_submit_content_div {
  margin: 10px 0px 20px 0px;
}

.sellSubmit .sell_submit_content_div h4 {
  font-size: 13px;
  font-weight: 500 !important;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.sellSubmit .sell_submit_content_div p {
  font-size: 16px;
  font-weight: 600 !important;
  margin-bottom: 10px;
}



.sellSubmit .text-danger {
  font-weight: 600 !important;
}

.MuiListItem-root.Mui-selected::after {
  content: '✔' !important;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* background-color: #ed1c24; */
  padding: 7px 15px;
  color: #ed1c24;
  transform: rotateZ(10deg);

}

.wrapper_images_design .edit-btn {
  bottom: 15px !important;
  left: 22px !important;
  background: white;
  border-radius: 17px;
  font-size: 12px;
  box-shadow: 0px 3px 6px #00000029;
  font-weight: 500;
  border: 1px solid #F5F6F7;
  width: 60px;
  height: 34px;
  color: black;
}

.wrapper_images_design .trash-btn {
  bottom: 15px !important;
  right: 10px !important;
  background: white;
  border-radius: 17px;
  font-size: 12px;
  box-shadow: 0px 3px 6px #00000029;
  font-weight: 500;
  width: 34px !important;
  height: 34px !important;
  border: 1px solid #F5F6F7;
}

/* Search selection style  */

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
  background: #f5f6f7 !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px !important;
}

.MuiAutocomplete-endAdornment {
  top: calc(50% - 15px) !important;
  right: 35px !important;
}

.PrivateNotchedOutline-root-1 .MuiOutlinedInput-notchedOutline {
  margin: 0px;
  padding: 0px 2px;
}

.MuiInputLabel-shrink {
  transform: translate(0px, 0px) scale(0) !important;
  display: none !important;
}

.label+.MuiInput-formControl {
  margin-top: 0px !important;
  padding: 10px 0px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  padding: 10px !important;
  background: #f5f6f7 !important;
  color: #616274;
  font-weight: 500;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child:focus {
  outline: none !important;
  border: none !important;
}

.MuiInputLabel-formControl {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(15px, 12px) scale(1) !important;
  z-index: 1;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
}

label+.MuiInput-formControl {
  margin-top: 0px !important;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: hsl(0, 60%, 92%);
  color: #616274;
  position: relative;
  overflow: hidden;
}

.MuiAutocomplete-option[data-focus="true"]::after {
  content: '✔' !important;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  /* background-color: #ed1c24; */
  padding: 7px 15px;
  color: #ed1c24;
  transform: rotateZ(10deg);
}

@media screen and (max-width: 968px) {
  .upload-btn {
    margin-top: 54px;
    position: relative;
    left: 13px;
    top: -15px;
  }

  .sell_your_car .text-wrapper h1 {
    font-size: 16px !important;
    margin-bottom: 0.5rem !important;
    padding-top: 10px;
    text-align: center;
    padding-left: 0px !important;
  }

  .sell_your_car .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

  }


  .wrapper-image-box {
    margin-top: 20px;
    width: auto;
    height: auto;
  }

  .wrapper-2 {
    min-height: 260px !important;
    height: auto !important;
  }

  .sell-a-car .list-dt-6,
  .sell-a-car .list-dt-5 {
    width: 300px;
  }

  .MuiMenuItem-root p {
    font-size: 12px !important;
  }

  .MuiMenuItem-root span {
    font-size: 12px !important;
  }

  .img {
    padding-left: 10px !important;
    padding-right: 12px;
  }

  .logo-1 {
    margin-top: 10px;
  }

  .flex-1 {
    display: flex;
    flex-direction: column;
  }

  .time_divs {
    display: flex;
    flex-direction: column;
  }

  .sell-a-car .set_availability {
    display: flex;
    flex-direction: column;
  }

  .time_div .MuiInput-root {
    width: 300px;
  }

  .sell-a-car .wrapper-days {
    padding-left: 1px;
    width: 330px;
  }

  .wrapper-days .btn-wrap {
    font-size: 12px;
    padding: 6px;
  }

  .sell-a-car .AddBtn {
    font-size: 12px;
    height: 130px;
  }

  .sell-a-car #fieldset-2 .action-button {
    font-size: 12px !important;
    width: 115px;
    position: fixed !important;
    bottom: 1.5rem !important;
    right: 1rem !important;
    z-index: 1000;
  }

  .sell-a-car .AddBtn .nav-btns-1 {
    font-size: 11px !important;
  }

  .sellSubmit .wrap-text-111 {
    padding: 10px 60px !important;
  }

  .display-wrapper-sell {
    padding-left: 0px !important;
  }

  .display-wrapper-sell .nav-btns {
    justify-content: flex-start !important;
  }

  .display-wrapper-sell .nav-btns-2,
  .display-wrapper-sell .nav-btns-1 {
    font-size: 12px !important;
    padding: 10px !important;
  }

  .progress_col h1 {
    font-size: 1.5rem !important;
  }

  .progress_col .progress_bar_width {
    width: 100vw !important;
  }
}