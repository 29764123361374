@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

* {
  font-family: 'Poppins', sans-serif;
}

.wrapper-2 {
  width: auto;
  height: 100px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #00000080;
  opacity: 1;
  margin-left: 0px;
  font-weight: 600;
  font-size: 16px;
}

.logo-1,
.logo-2 {
  margin-top: 30px;
}

.upload-btn {
  margin-top: 20px;
  position: absolute;
  right: 3rem;
}

.logo-text {
  text-align: left;
  font: normal normal medium 12px/16px Poppins;
  font-size: 12px;
  color: #000000;
  opacity: 1;
}


.img {
  padding-bottom: 50px;
  padding-left: 60px;
  padding-right: 20px;
}

.btn-wrap {
  padding: 9px 12px;
  border: 1px solid;
  color: black;
  text-transform: capitalize;
  cursor: pointer !important;
  margin: 5px 1px;
}

.wrapper-days {
  padding-left: 1rem;
  padding-top: 2rem;
  width: 600px;
}

.wrapper-days .btn {
  border-radius: 0px !important;
}

.wrapper-days .btn:focus {
  background: #ed1c24 !important;
  color: white;
  outline: none !important;
  /* border: none !important; */
  box-shadow: none !important;
}

.btn-bg {
  background-color: hsl(355deg 67% 96%);
  color: red;
}

.btn-default {
  width: 205px;
  height: 50px;
  background: #ed1c24 0% 0% no-repeat padding-box;
  padding: 10px 30px;
  color: white;
  margin-left: 40px;
  margin-top: 4px;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.btn-default:hover {
  background: #ed1c24 0% 0% no-repeat padding-box;
  padding: 10px 30px;
  color: white;
  margin-top: 4px;
  display: flex;
  font-weight: 600;
  font-size: 18px;
  transform: scale(1.1);
}

.btn-default img {
  margin-right: 10px;
}

.buy_a_car .text-para-22 {
  position: absolute;
  top: 43px;
  left: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.658);
  z-index: 1000;
}

.AddBtn {
  margin-top: 2rem;
  display: flex;
}

.AddBtn-btn {
  border: 0;
  color: red;
  padding: 15px;
  font-size: 16px;
  background-color: hsl(355deg 67% 96%);
  text-transform: capitalize;
  display: flex;
}

.AddBtn-btn:hover {
  text-decoration: none;
  transform: scale(1);
}

.text-para-33 {
  position: absolute;
  top: 10px;
  left: 33px;
  font-size: 14px;
  font-weight: 600;
  font-family: sans-serif;
  color: rgba(0, 0, 0, 0.658);
  z-index: 1000;
}

.buy_a_car .fieldset-2 .wrapper {
  font-size: 14px !important;
}

.modal-button {
  padding: 10px 370px 10px 0px;
}

.MuiInput-root {
  padding: 0px;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
  font-style: normal !important;
  color: #aab5c2 !important;
}

.buy_a_car .MuiSelect-select.MuiSelect-select {
  padding: 10px 10px 10px 90px;
  width: 135px !important;
  color: black;
  opacity: 60%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.buy_a_car #fieldset-2 .MuiSelect-select.MuiSelect-select {
  padding: 10px 10px 10px 20px;
  width: 135px !important;
  color: black;
  opacity: 60%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.buy_a_car_bottom .MuiSelect-select.MuiSelect-select {
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 450px !important;
  height: 30px;
  color: black;
  background: #f5f6f7 0% 0% no-repeat padding-box;
  opacity: 60%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.MuiSelect-select.MuiSelect-select em {
  font-style: normal !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: #fef3f3 !important;
  color: black !important;
  font-weight: 500 !important;
}

.buy_a_car .MuiListItem-root.Mui-selected::after {
  content: '✔' !important;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 50px;
  /* background-color: #ed1c24; */
  padding: 7px 15px;
  color: #ed1c24;
  transform: rotateZ(10deg);

}

.MuiMenuItem-root:hover {
  background-color: #ed1c24 !important;
  color: white !important;
}

.MuiInput-underline:before {
  content: "" !important;
  border-bottom: none !important;
}

.MuiInput-underline.Mui-focused:after {
  content: "" !important;
  border-bottom: none !important;
}

.buy_a_car #fieldset-2 .mul_cities .MuiSelect-select.MuiSelect-select {
  width: 400px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  height: 30px;
  display: flex;
  align-items: center;
  background: #f5f6f7;
}

.buy_a_car #fieldset-2 .MuiSelect-select.MuiSelect-select {
  width: 948px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  height: 30px;
  display: flex;
  align-items: center;
}



.buy_a_car #fieldset-2 span .MuiSelect-select.MuiSelect-select {
  font-weight: normal !important;
}

.buy_a_car .MuiButtonBase-root {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding-left: 20px !important;

}

.div_priorty .MuiSelect-select.MuiSelect-select {
  width: 200px !important;
}

.buy_a_car #fieldset-2 .set_availability .MuiSelect-select.MuiSelect-select {
  width: 120px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  background: #f5f6f7;
  padding-left: 80px;
  margin: 5px;
}

.buy_a_car .MuiInput-root {
  margin-right: 10px !important;
  background: #f5f6f7;
}

.buy_a_car_bottom .MuiInput-root {
  margin-right: 100px !important;
  background: #f5f6f7;
}

.buy_a_car .MuiListItem-button {
  width: 450px !important;
  padding-left: 45px !important;
}

.buy_a_car .MuiInputLabel-formControl {
  top: 4px;
  left: 25px;
  position: absolute;
  z-index: 1;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px;
  color: black;
  opacity: 60%;
}

.buy_a_car .MuiFormLabel-root.Mui-focused {
  display: none !important;
}

.buy_a_car .MuiInputBase-root {
  display: inline;
}

.mul_cities .MuiSelect-icon {
  top: 15%;
}

.buy_a_car #fieldset-1 .action-button {
  position: fixed;
  z-index: 1000;
  bottom: 1rem;
  right: 2rem;
  width: 230px;
  background-color: #ed1c24 !important;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  font-family: "Poppins", sans-serif;
  margin: 10px 5px;
}


.buy_a_car #fieldset-2 .action-button {
  position: fixed;
  z-index: 1000;
  bottom: 1rem;
  right: 2rem;
  width: 180px;
  background-color: #ed1c24 !important;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  font-family: "Poppins", sans-serif;
  margin: 10px 5px;
}

.buy_a_car #fieldset-3 .action-button {
  position: fixed;
  z-index: 1000;
  bottom: 1rem;
  right: 2rem;
  width: 230px;
  background-color: #ed1c24 !important;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  font-family: "Poppins", sans-serif;
  margin: 10px 5px;
}

.buy_a_car .input-11 {
  border: 0 !important;
  padding: 18px 44px !important;
  background: #f5f6f7 0% 0% no-repeat padding-box;
  margin-right: 5px;
  font-size: 1rem;
  height: 50px;
  width: 290px;
}

.guess_min {
  position: absolute;
  width: 242px !important;
  font-size: 12px !important;
  top: 55px;
  padding: 10px 15px;
}

/* Start of buy submit code  */

.buySubmit {
  padding: 0px 120px;
}

.buySubmit td span {
  font-weight: 500 !important;
  text-transform: uppercase;
}

.buySubmit table {
  width: 1100px !important;

}

.buySubmit .text-danger {
  font-weight: 600 !important;
}

.buySubmit .sell_submit_content_div {
  margin: 10px 0px 20px 0px;
}

.buySubmit .sell_submit_content_div h4 {
  font-size: 13px;
  font-weight: 500 !important;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.buySubmit .sell_submit_content_div p {
  font-size: 16px;
  font-weight: 600 !important;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .buySubmit {
    padding: 0px 10px;
  }

  .years_select_div {
    display: flex;
    flex-direction: column;
    height: 108px;
    justify-content: space-around;
  }

  .buy_a_car_bottom {
    flex-direction: column;
    margin-top: 24px;
  }


  .buy_a_car_bottom .MuiSelect-select.MuiSelect-select {
    width: 200px !important;
  }

  .buy_a_car #fieldset-1 .action-button,
  .buy_a_car #fieldset-2 .action-button,
  .buy_a_car #fieldset-3 .action-button {
    right: 10px;
    bottom: 0.5rem;
    width: 137px;
    font-size: 14px;
  }

  .buy_a_car_bottom .MuiInput-root {
    margin-right: 0px !important;
  }

  .buy_a_car fieldset-3 .col-lg-12 {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .buy_a_car .row {
    margin-top: 20px !important;
  }

  .buy_a_car #fieldset-2 .MuiSelect-select.MuiSelect-select {
    width: 220px !important;
  }

  .buy_a_car .contact_divs_column {
    flex-direction: column;
    margin-bottom: 5px;
  }

  .buy_a_car .contact_divs_column .wrap-1,
  .buy_a_car .contact_divs_column .wrap-2,
  .buy_a_car .contact_divs_column .wrap-3 {
    margin-bottom: 15px;
  }

  .buy_a_car .buy_notes_area {
    margin-top: 20px;
  }

  .buy_a_car .buy_notes_area textArea {
    margin-bottom: 80px;
  }

  .buy_a_car #fieldset-2 .mul_cities .MuiSelect-select.MuiSelect-select {
    width: 220px !important;
  }

  .buy_a_car .text-para-22 {
    top: 50px !important;
  }

  .submit_bottom_div .display-wrapper {
    /* padding-left: 0px !important; */
  }

  .address_div_mobile {
    flex-direction: column;
    margin-top: 0px !important;
  }

  .contact_divs_column {
    margin-bottom: 0px;
  }

  .buy-submit-bottom .display-wrapper {
    padding-left: 0px !important;
  }

  .buy-submit-bottom .display-wrapper .nav-btns {
    justify-content: flex-start !important;
  }


  .buy-submit-bottom .display-wrapper button {
    font-size: 12px;
    padding: 13px !important;
  }
}